import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService, StateUtilsService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { Operation } from '@dougs/operations/dto';
import {
  InvoiceError,
  InvoiceStat,
  SalesInvoice,
  SalesInvoiceDraft,
  SalesInvoicePaymentStatus,
} from '@dougs/sales-invoice/dto';
import { SalesInvoiceHttpService } from '../http/sales-invoice.http';
import { finalizedInvoiceSortFn, invoiceSortFn } from '../utils/sort-sales-invoice.utils';

interface SalesInvoiceState {
  invoiceStat: InvoiceStat;
  salesInvoices: SalesInvoice[];
  filteredSalesInvoices: SalesInvoice[];
  salesInvoicesDraft: SalesInvoiceDraft[];
  invoiceDraftDetail: SalesInvoiceDraft;
  invoiceErrors: InvoiceError[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class SalesInvoiceStateService extends StateService<SalesInvoiceState> {
  constructor(
    private readonly salesInvoiceHttpService: SalesInvoiceHttpService,
    private readonly logger: LoggerService,
    private readonly router: Router,
  ) {
    super();
  }

  invoiceStat$: Observable<InvoiceStat> = this.select((state: SalesInvoiceState) => state.invoiceStat);
  salesInvoices$: Observable<SalesInvoice[]> = this.select((state: SalesInvoiceState) =>
    (state?.salesInvoices ?? []).sort(finalizedInvoiceSortFn),
  );
  filteredSalesInvoices$: Observable<SalesInvoice[]> = this.select((state: SalesInvoiceState) =>
    (state?.filteredSalesInvoices ?? []).sort(finalizedInvoiceSortFn),
  );
  salesInvoiceDrafts$: Observable<SalesInvoiceDraft[]> = this.select(
    (state: SalesInvoiceState) => state.salesInvoicesDraft,
  );
  invoiceDraftDetail$: Observable<SalesInvoiceDraft> = this.select(
    (state: SalesInvoiceState) => state.invoiceDraftDetail,
  );
  invoiceErrors$: Observable<InvoiceError[] | null> = this.select((state: SalesInvoiceState) => state.invoiceErrors);

  async refreshInvoiceDetail(companyId: number | string, invoiceId: string): Promise<SalesInvoiceDraft | null> {
    try {
      const invoiceDetail: SalesInvoiceDraft = await lastValueFrom(
        this.salesInvoiceHttpService.getSalesInvoiceDraft(companyId, invoiceId),
      );
      this.setState({
        invoiceDraftDetail: invoiceDetail,
      });
      return invoiceDetail;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshSalesInvoiceById(companyId: number, salesInvoiceId: string): Promise<SalesInvoice | null> {
    try {
      const salesInvoice: SalesInvoice = await lastValueFrom(
        this.salesInvoiceHttpService.getSalesInvoice(companyId, salesInvoiceId),
      );
      if (salesInvoice) {
        this.setState({
          salesInvoices: this.state?.salesInvoices?.some(
            (salesInvoiceIterated) => salesInvoiceIterated.id === salesInvoice?.id,
          )
            ? this.state?.salesInvoices?.map((salesInvoiceIterated) =>
                salesInvoiceIterated.id === salesInvoice?.id ? salesInvoice : salesInvoiceIterated,
              )
            : [...(this.state?.salesInvoices || []), salesInvoice],
        });
      }
      return salesInvoice;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshInvoiceStat(companyId: number | string): Promise<void> {
    try {
      this.setState({
        invoiceStat: await lastValueFrom(this.salesInvoiceHttpService.getStats(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshSalesInvoices(
    companyId: number,
    filter: SalesInvoicePaymentStatus | null,
    offset = 0,
    limit = 20,
  ): Promise<void> {
    try {
      const salesInvoices: SalesInvoice[] = await lastValueFrom(
        this.salesInvoiceHttpService.getSalesInvoices(companyId, filter, offset, limit),
      );
      this.setState({
        filteredSalesInvoices: offset
          ? [...(this.state?.filteredSalesInvoices || []), ...salesInvoices]
          : salesInvoices,
      });
      if (!filter) {
        this.setState({
          salesInvoices: offset ? [...(this.state?.salesInvoices || []), ...salesInvoices] : salesInvoices,
        });
      }
    } catch (e) {
      this.logger.error(e);
      this.setState({
        salesInvoices: [],
        filteredSalesInvoices: [],
      });
    }
  }

  async refreshSalesInvoicesDraft(companyId: number, offset = 0, limit = 20): Promise<void> {
    try {
      const salesInvoicesDraft: SalesInvoiceDraft[] = await lastValueFrom(
        this.salesInvoiceHttpService.getSalesInvoicesDraft(companyId, offset, limit),
      );
      this.setState({
        salesInvoicesDraft: offset
          ? [...(this.state?.salesInvoicesDraft || []), ...salesInvoicesDraft]
          : salesInvoicesDraft,
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({
        salesInvoicesDraft: [],
      });
    }
  }

  async deleteSalesInvoiceDraft(invoiceDraft: SalesInvoiceDraft): Promise<void> {
    try {
      const filteredInvoices: SalesInvoiceDraft[] = StateUtilsService.filterInState(
        this.state?.salesInvoicesDraft ?? [],
        invoiceDraft,
      );
      this.setState({
        salesInvoicesDraft: filteredInvoices,
      });
      await lastValueFrom(
        this.salesInvoiceHttpService.deleteSalesInvoiceDraft(
          invoiceDraft.companyId ?? invoiceDraft.invoicerId,
          invoiceDraft.id,
        ),
      );

      await this.refreshInvoiceStat(invoiceDraft.companyId ?? invoiceDraft.invoicerId);
    } catch (e) {
      this.logger.error(e);
      this.rollback();
    }
  }

  async createSalesInvoiceDraft(companyId: number): Promise<void> {
    try {
      const createdSalesInvoiceDraft: SalesInvoiceDraft = await lastValueFrom(
        this.salesInvoiceHttpService.createSalesInvoiceDraft(companyId),
      );
      await this.router.navigate([`${this.router.url}`, createdSalesInvoiceDraft.id]);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadSalesInvoice(
    companyId: number,
    file: File,
    shouldBeAddedToFilteredList = false,
  ): Promise<SalesInvoice | null> {
    try {
      const salesInvoice: SalesInvoice = await lastValueFrom(
        this.salesInvoiceHttpService.uploadSalesInvoice(companyId, file),
      );
      this.setState({
        salesInvoices: [...(this.state?.salesInvoices || []), salesInvoice],
        filteredSalesInvoices: shouldBeAddedToFilteredList
          ? [...(this.state?.filteredSalesInvoices || []), salesInvoice]
          : this.state?.filteredSalesInvoices ?? [],
      });
      return salesInvoice;
    } catch (e: any) {
      if (e.status === 409) {
        const errorResponse: { existingSalesInvoiceId: string } | undefined = e?.error?.error;
        if (errorResponse?.existingSalesInvoiceId) {
          const alreadyExistingVendorInvoice: SalesInvoice | null = await this.refreshSalesInvoiceById(
            companyId,
            errorResponse.existingSalesInvoiceId,
          );
          if (alreadyExistingVendorInvoice) {
            return { ...alreadyExistingVendorInvoice, hasAlreadyBeenUploaded: true };
          }
        }
      }
      this.logger.error(e);
      return null;
    }
  }

  async deleteSalesInvoice(companyId: number, salesInvoiceId: string): Promise<void> {
    try {
      await lastValueFrom(this.salesInvoiceHttpService.deleteSalesInvoice(companyId, salesInvoiceId));
      this.setState({
        salesInvoices: (this.state?.salesInvoices || []).filter((invoice) => invoice.id !== salesInvoiceId),
        filteredSalesInvoices: (this.state?.filteredSalesInvoices || []).filter(
          (invoice) => invoice.id !== salesInvoiceId,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadLogo(invoice: SalesInvoiceDraft, file: File): Promise<void> {
    try {
      await lastValueFrom(this.salesInvoiceHttpService.uploadLogo(invoice.companyId ?? invoice.invoicerId, file));
      await this.refreshInvoiceDetail(invoice.companyId ?? invoice.invoicerId, invoice.id);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async duplicateInvoice(invoice: SalesInvoice | SalesInvoiceDraft): Promise<SalesInvoiceDraft | null> {
    try {
      const isDraft: boolean = invoice.isDraft;
      const invoiceDuplicated: SalesInvoiceDraft = await lastValueFrom(
        isDraft
          ? this.salesInvoiceHttpService.duplicateSalesInvoiceDraft(invoice.companyId ?? invoice.invoicerId, invoice.id)
          : this.salesInvoiceHttpService.duplicateSalesInvoice(invoice.companyId ?? invoice.invoicerId, invoice.id),
      );
      const newInvoices: SalesInvoiceDraft[] = StateUtilsService.addInState(
        this.state?.salesInvoicesDraft ?? [],
        invoiceDuplicated,
        invoiceSortFn,
      );
      this.setState({
        salesInvoicesDraft: newInvoices,
      });
      await this.refreshInvoiceStat(invoice.companyId ?? invoice.invoicerId);

      return invoiceDuplicated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateInvoice(invoice: SalesInvoiceDraft | SalesInvoice): Promise<boolean> {
    try {
      const isDraft: boolean = invoice.isDraft;
      let invoiceUpdated: SalesInvoice | SalesInvoiceDraft;
      if (isDraft) {
        invoiceUpdated = await lastValueFrom(
          this.salesInvoiceHttpService.updateSalesInvoiceDraft(invoice as SalesInvoiceDraft),
        );
      } else {
        invoiceUpdated = await lastValueFrom(this.salesInvoiceHttpService.updateSalesInvoice(invoice as SalesInvoice));
      }
      this.modifyInvoiceAfterUpdate(invoiceUpdated);

      if (this.state.invoiceErrors && isDraft) {
        await this.canFinalizeInvoice(invoiceUpdated as SalesInvoiceDraft);
      }

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async updateSalesInvoiceDraftWithoutStateUpdate(invoice: SalesInvoiceDraft): Promise<SalesInvoiceDraft | null> {
    try {
      const updatedSalesInvoiceDraft: SalesInvoiceDraft | null = await lastValueFrom(
        this.salesInvoiceHttpService.updateSalesInvoiceDraft(invoice),
      );
      if (this.state.invoiceErrors) {
        await this.canFinalizeInvoice(invoice as SalesInvoiceDraft);
      }
      return updatedSalesInvoiceDraft;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async acceptMatchedOperation(salesInvoice: SalesInvoice): Promise<SalesInvoice | null> {
    try {
      const salesInvoiceUpdated: SalesInvoice = await lastValueFrom(
        this.salesInvoiceHttpService.acceptMatchedOperation(salesInvoice),
      );
      if (salesInvoiceUpdated) {
        this.modifyInvoiceAfterUpdate(salesInvoiceUpdated);
      }
      return salesInvoiceUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async rejectMatchedOperation(salesInvoice: SalesInvoice): Promise<SalesInvoice | null> {
    try {
      const salesInvoiceUpdated: SalesInvoice = await lastValueFrom(
        this.salesInvoiceHttpService.rejectMatchedOperation(salesInvoice),
      );
      if (salesInvoiceUpdated) {
        this.modifyInvoiceAfterUpdate(salesInvoiceUpdated);
      }
      return salesInvoiceUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  resetInvoiceErrors(): void {
    this.setState({
      invoiceErrors: null,
    });
  }

  async canFinalizeOffer(invoice: SalesInvoiceDraft): Promise<InvoiceError[] | null> {
    try {
      const invoiceErrors: InvoiceError[] = await lastValueFrom(
        this.salesInvoiceHttpService.canFinalizeOffer(invoice.companyId ?? invoice.invoicerId, invoice.id),
      );

      this.setState({
        invoiceErrors,
      });

      return invoiceErrors;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async canFinalizeInvoice(invoice: SalesInvoiceDraft): Promise<InvoiceError[] | null> {
    try {
      const invoiceErrors: InvoiceError[] = await lastValueFrom(
        this.salesInvoiceHttpService.canFinalize(invoice?.companyId ?? invoice.invoicerId, invoice.id),
      );

      this.setState({
        invoiceErrors,
      });

      return invoiceErrors;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async finalizeInvoice(invoice: SalesInvoiceDraft): Promise<SalesInvoiceDraft | null> {
    try {
      const finalizedInvoice: SalesInvoiceDraft = await lastValueFrom(
        this.salesInvoiceHttpService.finalize(invoice.companyId ?? invoice.invoicerId, invoice.id),
      );
      return finalizedInvoice;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async attachOperation(operation: Operation, salesInvoice: SalesInvoice): Promise<SalesInvoice | null> {
    try {
      const salesInvoiceUpdated: SalesInvoice = await toPromise(
        this.salesInvoiceHttpService.attachOperation(operation.id, salesInvoice),
      );
      this.modifyInvoiceAfterUpdate(salesInvoiceUpdated);
      return salesInvoiceUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async detachOperation(operation: Operation, salesInvoice: SalesInvoice): Promise<SalesInvoice | null> {
    try {
      const salesInvoiceUpdated: SalesInvoice = await toPromise(
        this.salesInvoiceHttpService.detachOperation(operation.id, salesInvoice),
      );
      this.modifyInvoiceAfterUpdate(salesInvoiceUpdated);
      return salesInvoiceUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateSalesInvoiceFromSocket(companyId: number, salesInvoiceId: string): Promise<SalesInvoice | null> {
    const currentSalesInvoice: SalesInvoice | undefined = this.state?.salesInvoices?.find(
      (salesInvoice) => salesInvoice.id === salesInvoiceId,
    );
    if (currentSalesInvoice) {
      const salesInvoice: SalesInvoice | null = await this.refreshSalesInvoiceById(companyId, salesInvoiceId);
      if (salesInvoice) {
        this.modifyInvoiceAfterUpdate(salesInvoice);
      }
      return salesInvoice;
    }
    return null;
  }

  async abortPrefill(companyId: number, salesInvoiceId: string): Promise<void> {
    try {
      const salesInvoice: SalesInvoice = await toPromise(
        this.salesInvoiceHttpService.abortSalesInvoicePrefill(companyId, salesInvoiceId),
      );
      this.modifyInvoiceAfterUpdate(salesInvoice);
    } catch (e) {
      this.logger.error(e);
    }
  }

  findSalesInvoiceById(salesInvoiceId: string): SalesInvoice | undefined {
    return this.state?.salesInvoices?.find((salesInvoice) => salesInvoice.id === salesInvoiceId);
  }

  resetState(): void {
    this.setState({
      salesInvoices: [],
      filteredSalesInvoices: [],
      salesInvoicesDraft: [],
      invoiceStat: undefined,
      invoiceDraftDetail: undefined,
      invoiceErrors: null,
    });
  }

  resetInvoiceDraftDetail(): void {
    this.setState({
      invoiceDraftDetail: undefined,
    });
  }

  modifyInvoiceAfterUpdate(invoiceUpdated: SalesInvoiceDraft | SalesInvoice): void {
    if (invoiceUpdated?.isDraft) {
      if (this.state?.salesInvoicesDraft) {
        this.setState({
          salesInvoicesDraft: this.state.salesInvoicesDraft.map((invoiceIterated) =>
            invoiceUpdated.id === invoiceIterated.id ? (invoiceUpdated as SalesInvoiceDraft) : invoiceIterated,
          ),
          invoiceDraftDetail: invoiceUpdated as SalesInvoiceDraft,
        });
      } else {
        this.setState({
          invoiceDraftDetail: invoiceUpdated as SalesInvoiceDraft,
        });
      }
    } else if (this.state?.salesInvoices) {
      this.setState({
        salesInvoices: this.state.salesInvoices.map((invoiceIterated) =>
          invoiceUpdated.id === invoiceIterated.id ? (invoiceUpdated as SalesInvoice) : invoiceIterated,
        ),
        filteredSalesInvoices: this.state.filteredSalesInvoices.map((invoiceIterated) =>
          invoiceUpdated.id === invoiceIterated.id ? (invoiceUpdated as SalesInvoice) : invoiceIterated,
        ),
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Invoicer } from '@dougs/sales-invoice/dto';
import { InvoicerHttpService } from '../http/invoicer.http';

interface SalesInvoicerState {
  invoicer: Invoicer;
}

@Injectable({
  providedIn: 'root',
})
export class SalesInvoicerStateService extends StateService<SalesInvoicerState> {
  constructor(
    private readonly invoicerHttpService: InvoicerHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  invoicer$ = this.select((state: SalesInvoicerState) => state.invoicer);

  async refreshInvoicer(companyId: number | string): Promise<void> {
    try {
      const invoicer: Invoicer = await lastValueFrom(this.invoicerHttpService.getInvoicer(companyId));
      this.setState({
        invoicer,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateInvoicer(invoicer: Invoicer): Promise<boolean> {
    try {
      const invoicerUpdated: Invoicer = await lastValueFrom(this.invoicerHttpService.updateInvoicer(invoicer));
      this.setState({
        invoicer: invoicerUpdated,
      });

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }

  async deleteLastSalesInvoiceForCompany(companyId: number): Promise<boolean> {
    try {
      await lastValueFrom(this.invoicerHttpService.deleteLastSalesInvoiceForCompany(companyId));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}

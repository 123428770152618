import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';

@Injectable({
  providedIn: 'root',
})
export class CanActivateSalesInvoiceGuard {
  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly routingService: RoutingService,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    if (
      [SERVICE_STATE.ARCHIVED, SERVICE_STATE.TERMINATED, SERVICE_STATE.SUSPENDED, SERVICE_STATE.NOT_STARTED].includes(
        this.companyServicesStateService.services?.invoicing.state,
      )
    ) {
      return this.routingService.createUrl([URL.HOME]);
    }

    return true;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Invoicer } from '@dougs/sales-invoice/dto';

@Injectable({
  providedIn: 'root',
})
export class InvoicerHttpService {
  constructor(private readonly http: HttpClient) {}

  getInvoicer(companyId: number | string): Observable<Invoicer> {
    return this.http.get<Invoicer>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/invoicer`);
  }

  updateInvoicer(invoicer: Invoicer): Observable<Invoicer> {
    return this.http.post<Invoicer>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${invoicer.companyId ?? invoicer.id}/invoicer`,
      invoicer,
    );
  }

  deleteLastSalesInvoiceForCompany(companyId: number): Observable<void> {
    return this.http.get<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/invoicer/actions/delete-last-sales-invoice`,
    );
  }
}

import { SalesInvoice, SalesInvoiceAutofillStatus, SalesInvoiceDisplay } from '@dougs/sales-invoice/dto';

export function invoiceSortFn(a: SalesInvoiceDisplay, b: SalesInvoiceDisplay): number {
  const aDate: number | null = a.date ? new Date(a.date).getTime() : null;
  const bDate: number | null = b.date ? new Date(b.date).getTime() : null;

  if (!aDate && !bDate) {
    return 0;
  }
  if (!aDate) {
    return -1;
  }
  if (!bDate) {
    return 1;
  }

  return bDate - aDate;
}

// Order status initialised first then null/undefined date first then date desc
export function finalizedInvoiceSortFn(a: SalesInvoice, b: SalesInvoice): number {
  const aIsInitialized: boolean = a.prefillStatus === SalesInvoiceAutofillStatus.INITIALISED;
  const bIsInitialized: boolean = b.prefillStatus === SalesInvoiceAutofillStatus.INITIALISED;

  if (aIsInitialized && bIsInitialized) {
    return 0;
  }
  if (aIsInitialized) {
    return -1;
  }
  if (bIsInitialized) {
    return 1;
  }

  return invoiceSortFn(a, b);
}

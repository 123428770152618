export const VAT_TERMS = [
  {
    vatType: 'freeBaseVAT',
    vatDefaultText: 'TVA non applicable - franchise en base article 293 B du CGI',
  },
  {
    vatType: 'exportOutsideEU',
    vatDefaultText: 'Exonération de TVA française, article 262, I du CGI',
  },
  {
    vatType: 'deliveryOutsideEU',
    vatDefaultText:
      'Autoliquidation (exonération de TVA selon l’article 262 ter I du CGI ou l’article 138 de la directive 2006/112/CE)',
  },
  {
    vatType: 'servicesOutsideEU',
    vatDefaultText: 'TVA non applicable - article 259-1 CGI',
  },
  {
    vatType: 'servicesInEU',
    vatDefaultText: 'Autoliquidation par le preneur Art. 283-2 du CGI',
  },
  {
    vatType: 'reverseChargeConstructionIndustry',
    vatDefaultText: 'Autoliquidation - CGI art. 283, 2 nonies',
  },
  {
    vatType: 'reverseChargeElectronicCommunicationsServices',
    vatDefaultText: 'Autoliquidation - CGI art. 283, 2 octies',
  },
  {
    vatType: 'reverseChargeOther',
    vatDefaultText: 'Autoliquidation - ',
  },
  {
    vatType: 'foreignVAT',
    vatDefaultText: 'TVA étrangère appliquée : ',
  },
  {
    vatType: 'other',
    vatDefaultText: '',
  },
];
